/* CSS for unirand */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

.not-for-ansatte {
  button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search):not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel),
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
    input[type="button"]:not(.red),
  input[type="submit"]:not(.submit-comment-button):not(.red),
  input[type="cancel"]:not(.red),
  .header-search-expand,
  button:not([class^="admin-frontpage"]):not([class^="admin-box-row"]):not([class^="vrtx-frontpage-box"]):not([class^="change-positions-in-row"]):not([class^="vrtx-edit-"]):not(.undo):not(.x-cancel) {
    background-color: @color-primary !important;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark !important;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
  }
  #head-wrapper {
    background: @color-light;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll
        90% 53%;
    }
    #head {
      background: url(../images/logo-cropped.jpg) no-repeat center left;
      .uio-app-name {
        display: none;
      }
      height: 120px;
      .head-menu {
        top: 15px;
        right: 110px;
        .language {
          display: none;
        }
        a {
          color: @color-dark;
          background-color: @color-light;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        img {
          position: absolute;
          top: 28px;
        }
        .logo-wrapper {
          background: transparent;
          width: 360px;
          height: 120px;
          display: inline-block;
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a {
      color: @color-neutral--light;
      padding: 10px 15px;
    }
    &.vrtx-active-item a {
      color: @color-dark;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}
#main {
  min-height: 300px;
}
#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-ancestor:hover a:after {
    border-color: @color-neutral--light transparent transparent transparent;
  }
  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;
    a {
      color: @color-link;
      background-color: @color-neutral--light;
      border-top: none !important;
      background: rgba(0, 0, 0, 0) url("../images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-secondary !important;
      color: @color-dark;
      border-top: none !important;
    }
    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}

table th {
  font-weight: bold;
}

#vrtx-content #hdr {
  margin-bottom: 15px;
}
/* Frontpage four boxes */
#main #vrtx-main-content {
  .grid-container {
    margin-bottom: 0px;
    padding-top: 25px;
  }
  .grid-container:last-of-type {
    padding-top: 0px;
    padding-bottom: 25px;
  }
}
.vrtx-frontpage-box.white-box {
  height: 200px !important;
  background: @color-light;
  width: 49%;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  padding: 14px;
  &.half-box-left {
    float: left;
  }
  &.half-box-right {
    float: right;
  }
  img {
    height: 60px;
    width: auto;
  }
}

#vrtx-additional-content .vrtx-frontpage-box {
  border-bottom: 4px solid #eaeaea;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

#main .vrtx-back a::before {
  background: url("../images/icon-chevron-back-red.svg") no-repeat 0 0.2ex;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-red.svg") no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-red.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-red.svg");
  }
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    height: 168px;
    background-color: @color-primary;
    *,
    a {
      color: @color-neutral--light;
    }
    // .vrtx-login-manage-link {
    //   background: rgba(0, 0, 0, 0)
    //     url("/vrtx/decorating/resources/dist/src/images/dropdown-black.png")
    //     no-repeat scroll right center / 22px auto;
    //   &:focus,
    //   &:hover {
    //     background-image: url("/vrtx/decorating/resources/dist/src/images/dropdown-black-hover.png");
    //   }
    // }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: none @color-light;
      #head {
        background: url(../images/logo.jpg) no-repeat center center;
        height: 130px;
        #header {
          img {
            padding: 0 10px 10px;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
          .logo-wrapper {
            display: block;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }

  #main {
    #vrtx-main-content {
      .grid-container {
        margin-bottom: 40px;
        background: @color-light;
        padding-top: 25px;
      }
      .grid-container:last-of-type {
        margin-bottom: 40px;
      }
    }
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
  #footer-wrapper {
    height: 396px !important;
  }
}

/* Mixin */
.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary: #c40419;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-secondary: @color-light;
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #eaedee;
@color-neutral--air: #e5e5e5;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;
